//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "theme-variables";
//@import "~@angular/material/theming";

//::ng-deep{

  /*************
  * Typography *
  **************/
  .title-1{
    display: inline;
    font-family: 'Poppins', sans-serif;
    color: $main;
    font-weight: 600;
    margin-bottom:2rem
  }
  .title-2{
    display: inline;

    font-family: 'Poppins', sans-serif;
    font-size: 1.9rem;
    line-height:3rem;
    color: $main;
    font-weight: 600;
    margin-bottom:2rem
  }
  .title-3{
    //display: inline;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    line-height:2rem;
    color: $main;
    font-weight: 600;
    margin-bottom:2rem
  }
  .text-small{ font-size: 0.9rem; }

  .link{
    color:$text;
    text-decoration: underline;
    &:hover{ opacity:0.8;}
  }

  @for $i from 1 through 9 {
      .weight-#{(9 - $i + 1 )}{
        font-weight: $i*100;
      }
  }

  /***************
  * Layout
  ****************/
  .container-fluid { padding:0; }
  .flex-spacer{
    width: 6rem;
    max-width: 6rem;
    height: 6rem;
    max-height: 6rem;
  }

  $xx-padding: 8rem;
  $x-padding: 5rem;
  $x-padding-mobile: 2rem;

  .x-container{
    padding: 0 $x-padding;
    @media all and (max-width: 959px){
      padding: 0 $x-padding-mobile;
    }
  }
  .y-container{ padding: $x-padding 0; }
  .xy-container{
    padding: $x-padding;
    @media  all and (max-width: 959px){
      padding: $x-padding $x-padding-mobile;
    }
  }
  .xyy-container{
    padding:$xx-padding $x-padding;
    @media  all and (max-width: 959px){
      padding:4rem $x-padding-mobile;
    }
  }
  .horizontal-space{
    padding-right: $x-padding;
    padding-left: $x-padding;
  }

  .vertical-space{
    padding-top:$xx-padding;
    padding-bottom:$xx-padding;
  }

  .container-row{
    display: flex;
    flex-direction: row;
  }
  .container-column{
    display: flex;
    flex-direction: column;
  }

  .j-c{ justify-content: center; }
  .j-s{ justify-content: flex-start; }
  .j-sb{ justify-content: space-between; }
  .a-c{ align-items: center; }
  .a-s{ align-items: flex-start; }

  .expand{ flex: 1 1 1px; }

  /*********
  * HEADER *
  **********/

  .header-gradient{
    background: $header-end;
    background: linear-gradient(90deg, $header-start 0%, $header-end 100%);
  }
  .header-gradient--opaque{
    background: $header-end;
    background: linear-gradient(90deg, $header-start_ 0%, $header-end_ 100%);
  }

  .header-transparent{
    background: rgba(0,0,0,0)!important;
  }

  /************
  * SCROLLBAR *
  *************/

  .scrollbar {
    background: transparentize($header-start_, 0.05%)
  }


  /**********
  * BUTTONS *
  **********/

  $btn-padding-v:0.8rem;

  .btn-pollstar{
    border-radius: 10px !important;
    padding: $btn-padding-v 2rem !important;
    font-size: 1rem;
    font-weight: normal;
  }
  .btn-pollstar-large{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .btn-pollstar-wide{
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .btn-pollstar-fullwidth{
       width:100%;
  }
  .btn-pollstar-small{
    padding: 0.4rem 1rem!important;
    font-size: 0.8rem!important;
    border-radius: 0.4rem!important;
  }
  .btn-pollstar-tiny{
    padding: 0.2rem 0.5rem!important;
    font-size: 0.8rem!important;
    border-radius: 0.4rem!important;
  }

  .link-button{
    color: $white;
    border: none;
    box-shadow: none;
    padding: $btn-padding-v 0 !important;
  }

  .btn-white{
    @include button-variant($white, $white, $main);
  }
  .btn-blue{
    @include button-variant($main, $main, $white);
  }

  .btn-outline-white{
    @include button-variant(
        $transparent, $white, $white,
        $white, $white, $main
    );
    background: rgba(0,0,0,0);
    &:hover, &:focus{
      &, & *{
        color:$main!important;
      }
    }
    &, & > *{
      font-weight: bold!important;
    }
  }
  .btn-gradient-orange{
    @include button-variant(
        $orange_start, $orange_start, $white,
        lighten($orange_start, 10%), lighten($orange_start, 10%), $white
    );
  }
  .btn-facebook{
    @include button-variant(
        $blue-dark, $blue-dark, $white,
        lighten($blue-dark, 10%), lighten($blue-dark, 10%), $white
    );
  }

  /**************
  * OTHER STYLES
  ***************/

  .mat-card{
    border-radius: 20px!important;
    padding:2.5rem!important;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.5)!important;
  }
  .mat-dialog-container{
    border-radius: 20px!important;
    padding:0!important;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.5)!important;
    width: 100%!important;
    //max-width: 95vw!important;
    max-width: 900px!important;
  }

  .dialog-container{
    max-height: 100vh!important;
  }

  .mat-dialog-content{
    overflow: visible!important;
  }

  //.cdk-overlay-pane{
  //  max-width: 100%!important;
  //  width: 100vw!important;
  //  min-height: 100vh;
  //
  //  .mat-dialog-container{
  //    background: rgba(255,255,255,0.9);
  //    border-radius: 0;
  //    padding:0!important;
  //
  //    .mat-card{
  //      background: rgba(0,0,0,0);
  //      width: 100%;
  //      height: 100%;
  //      border-radius: 0;
  //      box-shadow:none;
  //    }
  //  }
  //
  //}



  mat-form-field {
    .mat-form-field {
      &-underline {
        position: static;
      }
      &-wrapper {
        padding-bottom: 0;
      }
      &-subscript-wrapper {
        position: static;
        min-height: 1.25em;
        margin: 0!important;
        padding: 0!important;
        margin-top: -5px!important;
      }
    }
  }

  .mat-error{
    background: #f44336;
    color: white;
    border-radius: 0 0 5px 5px;
    padding:0.5rem;
  }
  .padding-x{
    padding: 3rem 5rem!important;
  }

  /**************
  * OTHER STYLES
  ***************/

  strong{
    font-weight: bold!important;
  }

  .opaque{
    opacity:0!important;
  }

//}
