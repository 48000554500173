// Custom Theming for Angular Material
@use '~@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Pollstar-primary: mat.define-palette(mat.$indigo-palette);
$Pollstar-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Pollstar-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Pollstar-theme: mat.define-light-theme((
  color: (
    primary: $Pollstar-primary,
    accent: $Pollstar-accent,
    warn: $Pollstar-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Pollstar-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";
// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
$enable-smooth-scroll: false;

// Theme customization
@import "theme/theme";


html, body{
  &, app-root, .app-root, app-shell, .app-shell{
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    //overflow-x: hidden;
    //min-height: 100vh;

  }
  &, & *{
    //@media($mat-small){
    //  min-height: -webkit-fit-content;
    //}
  }
}


.warning {
  background: yellow;
  color: black;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100% !important;
  width: 96%;
}


.success {
  background: green;
  color: black;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100% !important;
  width: 96%;
}


.error {
  background: #B22222;
  color: black;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100% !important;
  width: 96%;
}


.og {
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100% !important;
  width: 96%;
}


.custom-modalbox {
  width: 50%;
}
//.ng-scroll-viewport {
//  overscroll-behavior: unset !important;
//}


@media(max-width: 959px){
  .mat-paginator{ width:100%;}
  .mat-paginator-navigation-first, .mat-paginator-navigation-last{
    display: none!important;
  }
  .mat-paginator-navigation-prev{ order:1; }
  .mat-paginator-range-label{ order:2; text-align: center;}
  .mat-paginator-navigation-next{ order:3;}

  .mat-dialog-container{
    overflow: auto!important;
    display: flex!important;
    flex-direction: column!important;
  }

  .cdk-overlay-pane{
    max-width: 100%!important;
    //margin-top: 100px!important;
    //max-height: calc(100vh - 100px)!important;
  }
  .mat-dialog-container{
    //border-radius: 0!important;
  }
}





/* COOKIEBOT */



#CybotCookiebotDialog{
  &, strong {
    color:$text!important;
  }

  #CybotCookiebotDialogHeader{
    display:none!important;
  }

  // Top Tabs/navs
  .CybotCookiebotDialogNavItemLink{
    color:$text!important;
    padding:1.5rem!important;

    // TAb Active
    &.CybotCookiebotDialogActive{
      color:$main!important;
      border-bottom-color: $main!important;
    }
  }

  // Checkbox not checked
  #CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider{
    background-color: $text!important;
  }

  // Checkbox checked
  #CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider{
    background-color: $main!important;
  }


  // Coockiebot Attribution
  #CybotCookiebotDialogPoweredByText{
    margin-top: 1.5rem!important;
    margin-bottom: 0.5rem!important;
  }

}

#CookiebotWidget{
  &, strong {
    color:$text!important;
  }

  svg path, svg circle{
    fill:$main;
  }

  .CookiebotWidget-logo{
    circle{
      stroke: $white;
      stroke-width: 0.5px;
      fill: $main!important;
      transform: scale(0.9) translate(1px, 1px);
    }
  }
  .CookiebotWidget-consent-details button{
    color:$main!important;
  }
  .CookiebotWidget-approved{
    color:$main!important;
    font-weight: bold;
  }
}



// buttons Outlined
.CybotCookiebotDialogBodyButton, #CookiebotWidget-btn-withdraw{
  border-color: $main!important;
  color: $main!important;
  border-radius: 10px!important;
}

// button Fill
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll, #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change{
  //background-color: $main!important;
  border-radius: 10px!important;
  color: $white!important;
  background-color: $orange_start!important;
  border-color: $orange_start!important;
}

#CybotCookiebotDialogFooter{
  padding-top:2rem!important;
}


//
//.full-screen-panel, .full-screen-backdrop{
//  &, & .mat-dialog-container.ng-star-inserted{
//    overflow: hidden!important;
//    position: absolute;
//    top:0;
//    left:0;
//    margin:0;
//    padding:0;
//    width: 100vw!important;
//    height: 100vh!important;
//    max-width: 100vw!important;
//    max-height: 100vh!important;
//    border-radius: 0!important;
//    background: rgba(255,255,255,0);
//  }
//
//  width: 50vw!important;
//  background: #fff!important;
//
//}

.full-screen-panel, .full-screen-backdrop{
  padding: 2rem 0!important;

  &, & .mat-dialog-container.ng-star-inserted{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border-radius: 0!important;
  }


}
