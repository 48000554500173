@import './material-theme.scss';
@import './theme-variables';


.no-scrollbar, html > *{
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}


html, body{
  scroll-behavior: auto!important;
  &, ::ng-deep app-root, ::ng-deep .app-root, ::ng-deep app-shell, ::ng-deep.app-shell{
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    scroll-behavior: auto!important;

    @media(max-width: 959px){
      font-size: 10px;
      line-height: 10px;
    }

    //@extend .no-scrollbar;

  }
}



body {
  color: $text;
  font-size: 1rem;
  line-height: 1.5rem;
  //background: #f5f5f5;
  background: #fff;
  font-weight: 300;
  letter-spacing: 0.3px;
  overflow-x: hidden;

  $color1: #18A2B8;
  $color2: #bbe3f3;
  $color3: #0b4650;

  font-family: Roboto, "Helvetica Neue", sans-serif;


  p{
    font-weight: 300;
    margin-bottom: 0;
  }

  .link{
    text-decoration: underline;
    cursor: pointer;
    &:hover{
      color: $main!important;
    }
  }
  a{
    color:$text;
  }
  h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
    font-weight: 600;
  }
}

.desktop{
  display: initial;
  @media(max-width: 959px){ display: none }
}
.mobile{
  display: none;
  @media(max-width: 959px){ display: initial }
}

.cdk-overlay-container{
  z-index:10000;
}

button {
  &:focus, &:active {
    outline:none!important;
  }

}

header {
  nav {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0!important;
    margin-top: 0!important;
    padding-bottom: 0!important;
    padding-top: 0!important;
    & > * {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  @media all and(max-width: map-get($grid-breakpoints, md)){
    .navbar-collapse{
      background: $light;
      padding: 1rem;
      border-radius: 3px;
      & * {
        color: $dark;
      }
      box-shadow :
        0px 5px 5px -3px rgba(52, 58, 64, 0.2),
        0px 8px 10px 1px rgba(52, 58, 64, 0.14),
        0px 3px 14px 2px rgba(52, 58, 64, 0.12)
    ;

    .dropdown{
      border-top: solid 1px #efefef;
      margin-top: 1rem;
      padding-top: 1rem;

      .dropdown-menu{
        background: rgba(0,0,0,0)!important;
        display: block!important;
        border:none;
        position: relative!important;
        transform: none!important;
        & > * {
          padding: 0.5rem 0;
          border:none;
        }
        .dropdown-divider{
          display: none;
        }


      }
    }
  }

}


}

.pointer{
  cursor:pointer;
}

.disabled {
    & > *{
      color:$gray-600;
      &:hover { cursor: not-allowed; }
    }
    //pointer-events: none;
    cursor: default;
}

app-registration-form, app-login-form{
  .mat-form-field{
    margin-left:0;
  }
}

.survey-participant__confirmation-dialog{
  .mat-dialog-container { min-width: 50vw; }
}

.mat-dialog-container {
  overflow:hidden!important;
  .mat-dialog-content{
    overflow:hidden!important;
  }
  .mat-dialog-actions{
    margin-bottom:0!important;
    overflow:hidden!important;
  }
}

.reward-history{

  .mat-paginator{
    background: rgba(0,0,0,0);
    //color: $dark;

    .mat-paginator-container{
      padding: 0!important;
    }

    .mat-paginator-page-size{
      display: none!important;
    }
    .mat-paginator-range-actions{
      display: flex;flex-direction: row; justify-content: space-between;align-items: center;
      width: 100%;

      .mat-paginator-range-label{
        margin:0!important;
        flex: 1 1 1px;
        font-size: 0.9rem;
        font-weight: bold;
      }

    }
  }
}



////////////

$vertical-thumb-color: $header-start;
//$custom-scrollbar-palette: pri-scrollbar-palette($vertical-thumb-color);
//@include pri-scrollbar-theme($custom-scrollbar-palette);

//.pri-scrollbar-item {
//  z-index:1050;
//}

////////////

.mat-card {
  border-radius: 1.5px!important;
}

.mat-paginator-page-size{
  display: none!important;
}
.mat-paginator-range-actions{
  display: flex;flex-direction: row; justify-content: space-between;align-items: center;
  width: 100%;
  .mat-paginator-range-label{
    margin:17px!important;
    flex: 1 1 1px;
    font-size: 0.9rem;
    font-weight: bold;
  }

}

@import "common-styles";
