/*
 * Application global variables.
 */
// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// Color system
$enable-gradients : true;
$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$blue:    #0073dd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$main : #219590;
$secondary : #00446a ;

$transparent : rgba(0,0,0,0);
$header : #219590;


//$header-start_: #008dcf;
$header-start_: #007aaa;
//$header-end_: #6fc9ec;
$header-end_: #44c9f2;

$header-start: transparentize($header-start_, .05);
$header-end: transparentize($header-end_, .05);

$orange_start: #f17622;
$orange_end: #f58d45;

$background : #e7efef;

//$text:#2F2F31;
//$text: $gray-700;
$text: #6e6e6e;
$border:rgba(47,47,49,.25);

$z : #e7efef;

// theme A
$a: #e7e3d4;
$b: #c4dbf6;
$c: #3b8beb;
$d: #8590aa;
$e: #b23850;
$f: #006273;
$g: #484545;
$h: #3b4248;

// theme B1
$a1: #14a098;
$b1: #cb2d6f;
$c1: #cccccc;
$c1b: #eeeeee;
$d1: #501f3a;
$e1b: #0f292f;
$e1: #377791;

// Theme A2
$a2: #5f6366;
$b2: #4d6d9a;
$c2: #86b3d1;
$d2: #99CED4;
$e2: #edb5bf;

// Theme A3
$a3: #867864;
$b3: #317083;
$c3: #5085a5;
$d3: #8fc1e3;
$e3: #f7f9fb;
$f3: #008662;
$g3: #354B7D;
$h3: #444D4E;
$i3: #4b5a67;

$background : $e3;


//$main: #018DCF;
$main: #1186be;
$main-dark: #0e5c83;

$accent: #ff802b;

$blue-dark:#425dad;
$blue-night:#2a2f43;


//$info: $f;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
$info: $cyan;
$info: $accent;
$info-dark: #007a8c;
$subtitle:rgba(0, 0, 0, 0.54);
$header: $cyan;
$card-header: $h;

$text-bright: $b3;
$text-bright-hover: $g3;

$theme-colors: (
  "primary": $main,
  "primary-dark": $main-dark,
  "secondary": $gray-600,
  "accent": $accent,
  "success": $green,
  "info": $cyan,
  "info-dark": $info-dark,
  "warning": $accent,
  "danger": $red,
  "light": $gray-100,
  "medium": $gray-400,
  "dark": $gray-800,
  "header": $header,
  "white": $white,
  "subtitle": $subtitle,
  "blue-dark": $blue-dark,
  "blue-night": $blue-night
);

$grid-breakpoints:
(
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1680px
);

$mat-xsmall: 'max-width: 599px';
$mat-small: 'max-width: 959px';
